.gallery_section_page {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../images/banner/milestone.jpg");

  padding: 170px 0;
}

.gallery_section_page .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-start;
}

.galleryWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
}

.galleryWrap .single {
  width: calc(100% / 4);
  cursor: pointer;
  overflow: hidden;
}

.galleryWrap .single img {
  width: 100%;
}
.galleryWrap .single img:hover {
  transform: scale(1.02);
}

.sliderWrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sliderWrap .btnClose,
.sliderWrap .btnPrev,
.sliderWrap .btnNext {
  position: fixed;
  cursor: pointer;
  opacity: 0.6;
  color: #fff;
  z-index: 9999;
}

.btnNext:hover,
.btnClose:hover,
.btnPrev:hover {
  opacity: 1;
}

.sliderWrap .btnClose {
  top: 40px;
  right: 40px;
}

.sliderWrap .btnPrev {
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
}

.sliderWrap .btnNext {
  top: 50%;
  transform: translateY(-50%);
  right: 40px;
}

.fullScreenImage {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullScreenImage img {
  max-width: 100%;
  max-height: 100%;
  pointer-events: none;
  user-select: none;
}

@media (max-width: 768px) {
  .galleryWrap {
    display: block;
  }

  .galleryWrap .single {
    width: 100%;
  }
}
