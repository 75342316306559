.banner-section {
  /* background-position: center; */
  background-size: cover;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-image: url("../images/banner/pageheader.jpg");

  padding: 100px 0;
}


.banner-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100%;
}

.text-btn {
  font-size: 0.875rem;
  color: #000000;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.text-btn:hover {
  padding-left: 10px;
  color: #eb1d25;
}

/* deparment */

.department-section.style-2 {
  background-color: #f5f9ff;
  padding: 80px 0;
}

.department-section.style-2 .section-wrapper,
.department-section.style-1 .section-content .department-top .dep-tab {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  list-style: none;
}

.section-wrapper .lab-item {
  width: calc(50% - 0px);
}

.our-services {
  background: url(../images/banner/bg-dots.png) no-repeat center;
  background-size: cover;
}

.service-section.style-1
  .section-wrapper
  .service-item:nth-child(2)
  .service-content
  h4
  a,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(5)
  .service-content
  h4
  a,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(8)
  .service-content
  h4
  a,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(11)
  .service-content
  h4
  a {
  color: #ffffff !important;
}
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(2)
  .service-content
  p,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(5)
  .service-content
  p,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(8)
  .service-content
  p,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(11)
  .service-content
  p {
  color: #fff !important;
}
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(3)
  .service-content
  h4
  a,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(6)
  .service-content
  h4
  a,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(9)
  .service-content
  h4
  a,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(12)
  .service-content
  h4
  a {
  color: #ffffff !important;
}
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(3)
  .service-content
  p,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(6)
  .service-content
  p,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(9)
  .service-content
  p,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(12)
  .service-content
  p {
  color: #fff !important;
}
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(2)
  .service-content,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(5)
  .service-content,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(8)
  .service-content,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(11)
  .service-content {
  background: #3156a3 !important;
}
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(3)
  .service-content,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(6)
  .service-content,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(9)
  .service-content,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(12)
  .service-content {
  background: #379ff4 !important;
}

.section-header {
  max-width: 70%;
  margin: 0 auto 50px;
  text-align: center;
}

.department-section.style-2 .section-wrapper .lab-item .lab-item-inner {
  text-align: center;
  margin: 8.5px;
  background: #fff;
  padding: 30px 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.section-header span {
  display: block;
  font-weight: 300;
}

.department-section.style-1 {
  padding-bottom: 0;
}

.department-section.style-1 .section-wrapper {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.department-section.style-1 .section-content .department-top .dep-tab {
  margin: 0;
  margin-bottom: 10px;
}
.department-section.style-1 .section-content .department-top .dep-tab li {
  padding: 5px;
  text-align: center;
  width: calc(100% / 2 - 9px);
}

.department-section.style-1 .section-content .department-top .dep-tab li a {
  padding: 20px 13px;
  display: block;
  background: #f6faff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.department-section.style-1
  .section-content
  .department-top
  .dep-tab
  li
  a.active {
  box-shadow: 0 0 5px #80808033;
}
.department-section.style-1 .section-content .department-bottom {
  padding: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: -15px;
}

.department-section.style-2 .section-wrapper .lab-item .lab-item-inner {
  text-align: center;
  margin: 8.5px;
  background: #fff;
  padding: 30px 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 15px;
}
.department-section.style-2 .section-wrapper .lab-item .lab-item-inner h4 {
  margin-bottom: 0;
}
.department-section.style-2 .section-wrapper .lab-item .lab-item-inner img {
  padding: 20px 0;
}
.department-section.style-2 .section-wrapper .lab-item .lab-item-inner a {
  display: block;
  color: #000000;
  font-weight: 700;
}
.department-section.style-2 .section-wrapper .lab-item .lab-item-inner:hover {
  box-shadow: 0 15px 15px 3px rgba(0, 0, 0, 0.1);
}
.department-section.style-2 .section-wrapper .lab-item .lab-item-inner:hover a {
  color: #eb1d25;
}

.department-section.style-1
  .section-content
  .department-bottom
  .tab-content
  .tab-pane.active {
  opacity: 1;
  display: block;
}
.department-section.style-1
  .section-content
  .department-bottom
  .tab-content
  .post-content {
  padding: 20px 0px 20px 15px;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.department-section.style-1
  .section-content
  .department-bottom
  .tab-content
  .post-content
  h3 {
  margin-top: 0;
}
.department-section.style-1
  .section-content
  .department-bottom
  .tab-content
  .post-content
  p {
  line-height: 30px;
}
.department-section.style-1
  .section-content
  .department-bottom
  .tab-content
  .post-content
  ul {
  margin-left: 1.5rem;
  margin-bottom: 25px;
}

.updates-section {
  background: url("/public/updates-bg.jpg") no-repeat center center;
  background-size: cover;
  padding-top: 60px;
  margin-bottom: 200px;
}

#updates-slider {
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  bottom: -185px;
  position: relative;
  margin-top: -206px;
}

#updates-slider .updates-card {
  padding: 30px;
  /* border: 1px solid transparent; */
}

.updates-card:nth-child(1) {
  border-right: 1px solid #d7d7d7;
}
.updates-card:nth-child(2) {
  border-right: 1px solid #d7d7d7;
}

/* .updates-card:nth-child(even) {
    border-right: 1px solid red;
} */

.anchor-button span {
  width: 34px;
  height: 34px;
  background: red;
  border: none;
  font-size: 0px;
  border-radius: 30px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  display: block;
  transition: 0.3s;
}
.anchor-button span::after {
  content: "";
  position: absolute;
  top: 12px;
  left: 11px;
  height: 7px;
  width: 7px;
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform: rotate(45deg);
  pointer-events: none;
  border-radius: 1px;
}

.anchor-button {
  color: #7c7c7c;
  font-size: 15px;
  font-weight: 600;
  display: inline-flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

/* model */
.care_model .section-wrapper {
  padding: 100px 0;
}
.image_outer {
  margin: 0 auto;
  border: 1px solid #000;
  border-style: dashed;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: relative;
}

.image_inner {
  margin: 0 auto;
  border: 1px solid #000;
  border-style: dashed;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  position: relative;
}

.image_inner img {
  width: 420px;
  height: 420px;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.list_title_a {
  position: absolute;
  top: -80px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 30%;
  align-items: center;
}

.list_title_b {
  position: absolute;
  top: 130px;
  right: -250px;
  transform: translate(-0%, -0%);
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}
.list_title_c {
  position: absolute;
  bottom: 40px;
  right: -180px;
  transform: translate(-0%, -0%);
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}
.list_title_d {
  position: absolute;
  bottom: 10px;
  left: -260px;
  transform: translate(-0%, -0%);
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}
.list_title_e {
  position: absolute;
  top: 120px;
  left: -300px;
  transform: translate(-0%, -0%);
  text-align: right;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.res_section_wrapper {
  display: none;
  background: #ffffff;
  box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  margin: 0 20px;
  padding-bottom: 30px;
}

.resp_lists {
  display: block;
  position: relative;
  margin-top: 30px;
  padding: 10px 20px;
  list-style: none;
}

.resp_lists li {
  display: flex;
  font-size: 20px;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid black;
  align-items: center;
}

.resp_lists li p {
  font-size: 16px;
}
.click-text {
  padding-bottom: 0px;
}
.block-head {
  font-size: 1.2em;
  color: black;
  font-weight: bold;
}

.block-head:hover {
  color: #eb1d25;
}
.block-head.active {
  color: #eb1d25;
}

.click-circle {
  width: 28px;
  height: 28px;
  background: #fff;
  border: 1px solid #eb1d25;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  margin: 0 auto;
  margin-top: 15px;
}

.click-circle:hover {
  background: #eb1d25;
  border: none;
}
.click-circle.active {
  background: #eb1d25;
  border: none;
}

/* services */

.service_card_section {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.service_card {
  background: #fff;
  border-radius: 20px;
  flex: 1 1 21rem;
  margin: 10px 21px;
  /* box-shadow: 0 0 15px rgba(0, 0, 0, 0.4); */
}

.service_card img {
  width: 100%;
  height: 200px;
}

.card-desk {
  padding: 50px 50px;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.14);
  margin-bottom: 20px;
}

.card-desk h3 {
  margin-bottom: 5px;
}

.services-logo img {
  width: 50px;
  height: 50px;
}

.card-desk .anchor-button {
  color: #eb1d25;
}

.card-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* clients Story */

.story_container_section {
  display: flex;
  align-items: center;
  margin: 0 -7px;
}

.resp_story_container {
  display: none;
}

.story_container_section .video-col-1 {
  width: 18%;
}

.story_container_section .video-col-2 {
  width: 21.33%;
}

.story_container_section .video-wrap {
  padding: 7px;
}

.story_container_section .video {
  position: relative;
}

.video-wrap .video .video-thumb.ratio1 {
  padding-bottom: 110%;
}

.video-wrap .video .video-thumb.ratio2 {
  padding-bottom: 80%;
}
.video-wrap .video .video-thumb {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  line-height: 0;
}

.video-wrap .video .video-thumb:not(.default) img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.video-wrap .video .video-thumb .play-video {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 55;
  transform: scale(0.9);
  transition: 0.3s;
  cursor: pointer;
}

.video-wrap .video .video-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  text-align: center;
  color: #fff;
  z-index: 5;
}

.video-wrap .video .video-caption p {
  font-size: 11px;
}
.video-wrap .video .video-caption p {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.container .popup-media {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.container .popup-media iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 50%;
  height: 50%;
  object-fit: contain;
  border: 3px solid #fff;
}

/* gallery */

.gallery_section {
  background: #f7f3f3;
}

.container .popup-media img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 50%;
  height: 50%;
  object-fit: contain;
  border: 3px solid #fff;
}

.container .popup-media span {
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 50px;
  font-weight: bolder;
  z-index: 100;
  cursor: pointer;
  user-select: none;
  color: #fff;
}

/* awards */

.awards_wrapper {
  margin-bottom: 30px;
}

.awards_card {
  width: 100%;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.14);
  padding: 30px 90px;
  text-align: center;
  position: relative;
}

.banner-wrapper {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 575px) {
  .banner-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
  .banner-section {
    padding: 70px 0;
  }

  .banner-section.style-2 .contant-area .banner-contant h2 {
    font-size: 50px !important;
  }

  .banner-section.style-2 .contant-area .banner-contant h2 span {
    font-weight: 400;
  }
  .lab-btn {
    margin-top: 35px;
  }
  .banner-section.style-2 .contant-area .banner-contant span {
    z-index: 1;
  }

  .swiper-slide {
    width: 100% !important;
  }

  .faq_wrapper .accordion {
    width: 100%;
  }

  .list_title_a,
  .list_title_b,
  .list_title_c,
  .list_title_d,
  .list_title_e,
  .list_title_f {
    display: none;
  }
  .resp_lists {
    display: block;
  }
}

@media (max-width: 576px) {
  .service-section.style-1 .section-wrapper .service-item .service-inner {
    display: block;
  }

  .service-section.style-1
    .section-wrapper
    .service-item
    .service-inner
    .service-thumb {
    width: calc(100% / 1);
  }

  .service-section.style-1 .section-wrapper .service-item:nth-child(2),
  .service-section.style-1 .section-wrapper .service-item:nth-child(3),
  .service-section.style-1 .section-wrapper .service-item:nth-child(5),
  .service-section.style-1 .section-wrapper .service-item:nth-child(6),
  .service-section.style-1 .section-wrapper .service-item:nth-child(8),
  .service-section.style-1 .section-wrapper .service-item:nth-child(9),
  .service-section.style-1 .section-wrapper .service-item:nth-child(11),
  .service-section.style-1 .section-wrapper .service-item:nth-child(12) {
    width: 100%;
    float: none;
  }

  .service-section.style-1
    .section-wrapper
    .service-item
    .service-inner
    .service-content {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .section-wrapper .lab-item {
    width: calc(50% - 0px);
  }

  .service_card_section {
    display: block;
  }

  /*  clients story */
  .story_container_section {
    display: none;
  }

  .story_container_section .video-col-1,
  .story_container_section .video-col-2 {
    width: 100%;
  }

  .video-wrap .video .video-thumb.ratio1 {
    padding-bottom: 80%;
  }

  .resp_story_container {
    display: block;
  }

  /* model */

  .care_model .section-wrapper {
    padding: 0;
  }
  .res_section_wrapper {
    display: block;
  }

  .resp_image_outer {
    text-align: center;
    position: relative;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;

    border-bottom: 1px solid #000;
  }

  .resp_image_inner {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 20px 0;
    border: 1px dashed black;
    padding: 10px;
  }
  .resp_image_inner img {
    border-radius: 50%;
    width: 180px;
    height: 180px;
  }

  .container .popup-media iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 90%;
    height: 50%;
    object-fit: contain;
    border: 3px solid #fff;
  }

  .doctor_team_section {
    width: calc(100% / 1);
  }
}
@media (min-width: 1200px) {
  .department-section.style-2 .section-wrapper .lab-item {
    width: calc(100% / 5);
  }
}
