.page-header-section_treat.style-1 {
  /* background-image: url("../images/banner/pageheader.jpg"); */
  padding-top: 105px;
  padding-bottom: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  background-color: #f6f6f6;
}

.treatment_banner_wrapper .page-header-content-inner .page-title h2 {
  color: #eb1d25;
  text-transform: capitalize;
  text-align: left;
  margin-bottom: 20px;
}

.breadcrumb_treat {
  display: -ms-flexbox;
  display: block !important;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
}

.page-header-section.style-1
  .page-header-content
  .page-header-content-inner
  .breadcrumb
  li
  a {
  color: #000000;
  padding: 0 5px;
  outline: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-header-section.style-1
  .page-header-content
  .page-header-content-inner
  .breadcrumb
  li
  a {
  font-weight: 700;
}

.treatment_banner_wrapper {
  display: flex;
}

.page-header-content-inner {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.treatment_intro {
  width: 80%;
}

.treatment_intro img {
  height: 100%;
  width: 100%;
}

.resp_image {
  display: none;
}

.banner_image {
  display: block;
}

.sm_size_btn {
  display: none !important;
}
.lg_size_btn {
  display: block;
}

.treat_intro_list {
  margin: 15px 0;
  display: none;
}

.feature-block {
  padding-right: 20px;
  position: relative;
  padding-left: 40px;
}

.feature-block .title {
  font-size: 16px;
  line-height: 1.4;
}

.feature-block:before {
  border: 2px solid #f04e30;
  width: 20px;
  height: 20px;
  background-color: #fff;
  position: absolute;
  content: "";
  border-radius: 50%;
  left: 0;
  top: 5px;
}

.doctor_speality_wrapper {
  display: flex;
  align-items: center;
}

.spe_desp {
  width: 40%;
  color: #58595b;
}

.spe_doc {
  width: 40%;
}

.spe_intro {
  width: 40%;
}

.spec_image {
  line-height: 0;
  position: relative;
}

.spec_image:before {
  left: 0;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  width: 70%;
  margin: auto;
  padding-bottom: 70%;
  background-color: #eb1d25;
  border-radius: 50%;
  content: "";
  position: absolute;
}

.spec_image:after {
  left: 0;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  width: calc(70% + 30px);
  margin: auto;
  padding-bottom: calc(70% + 30px);
  border-radius: 50%;
  content: "";
  position: absolute;
  border: 1px dashed #505050;
}

.spec_image img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.spec_doctor_det p {
  color: #eb1d25;
}

.spec_team_wrapper {
  display: flex;
  width: 100%;
}

.team_card {
  width: 33.33%;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  background-color: #fff;
  margin-right: 20px;
}

.team_card img {
  width: 100%;
  border-radius: 20px;
}

.spea_team_desc {
  padding: 30px;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  background-color: #fff;
  margin-right: 20px;
  width: 100%;
}
.tech_sec {
  padding: 30px;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.14);
  border-radius: 20px;
  background-color: #fff;
  margin-right: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SwiperSlide img {
  width: 100% !important;
  height: 300px !important;
}

.treaments_step_wrapper {
  display: flex;
  align-items: center;
}

.treatment_lists,
.treatment_image,
.treatment_desc {
  width: 33.33%;
}

.treatment_lists ul {
  list-style: none;
}

.treat_list_grp li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #565656;
  padding: 10px;
  cursor: pointer;
}

.treatment_image {
  position: relative;
}

.treat_select_inner {
  text-align: center;
  width: 360px;
  height: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border: 1px dashed #323232;
  border-radius: 50%;
  position: relative;
  margin-bottom: 30px;
}

.treat_select_inner img {
  width: 340px;
  height: 340px;
  border-radius: 50%;
}

.list-title {
  color: #000;
}

.list-title.active {
  color: #eb1d25;
}

.sub_treatment_special {
  background: #ebe3e3;
}

.sub_dep_card {
  width: 100%;
  height: 300px;
  background: #fff;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.14);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sub_dep_card:hover {
  background: #eb1d25;
  color: #fff;
}

.sub_dept_title {
  margin-top: 10px;
}

.sub_dept_title h3 {
  text-align: center;
}

@media (max-width: 768px) {
  .treatment_banner_wrapper {
    display: block;
  }

  .page-header-content-inner {
    width: 100%;
  }

  .resp_image {
    display: block;
  }

  .treatment_intro {
    width: 100%;
  }

  .banner_image {
    display: none;
  }

  .sm_size_btn {
    display: block !important;
    margin-left: 100px;
    text-align: center;
  }
  .lg_size_btn {
    display: none !important;
  }

  .page-header-content-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .treat_intro_list {
    display: block;
  }

  .doctor_speality_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .spe_desp {
    width: 100%;
    margin-bottom: 50px;
    text-align: center;
  }

  .spe_doc {
    width: 100%;
    margin-bottom: 50px;
  }

  .spe_intro {
    width: 100%;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
  .team_card {
    width: 100%;
  }

  .treaments_step_wrapper {
    display: none;
  }

  .sub_dep_card img {
    height: 200px;
    width: 200px !important;
  }
}
