/* home banner end */
.page-header-section.style-1 {
  /* background-image: url("../images/banner/pageheader.jpg"); */
  padding-top: 105px;
  padding-bottom: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  background-color: #f6f6f6;
}

.page-header-section.style-1 .page-header-content .page-header-content-inner {
  text-align: center;
}

.page-header-section.style-1
  .page-header-content
  .page-header-content-inner
  .page-title
  h2 {
  color: #eb1d25;
  text-transform: capitalize;
}

.breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: center;
}

.page-header-section.style-1
  .page-header-content
  .page-header-content-inner
  .breadcrumb
  li
  a {
  color: #000000;
  padding: 0 5px;
  outline: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.page-header-section.style-1
  .page-header-content
  .page-header-content-inner
  .breadcrumb
  li
  a {
  font-weight: 700;
}

/* service section */

.service-section.style-1 .section-wrapper .service-item:nth-child(1),
.service-section.style-1 .section-wrapper .service-item:nth-child(4),
.service-section.style-1 .section-wrapper .service-item:nth-child(7),
.service-section.style-1 .section-wrapper .service-item:nth-child(10) {
  width: 100%;
}

.service-section.style-1 .section-wrapper .service-item .service-inner {
  display: flex;
  flex-wrap: wrap;
}

.service-section.style-1.style-3.padding-tb {
  background-image: url(../images/service/bg.jpg);
  background-size: cover;
  padding: 80px 0;
}

.service-section.style-1
  .section-wrapper
  .service-item
  .service-inner
  .service-thumb {
  width: calc(100% / 2);
}

.service-section.style-1
  .section-wrapper
  .service-item
  .service-inner
  .service-content {
  width: calc(100% / 2);
}

.service-section.style-1
  .section-wrapper
  .service-item:nth-child(4)
  .service-inner,
.service-section.style-1
  .section-wrapper
  .service-item:nth-child(10)
  .service-inner {
  flex-direction: row-reverse;
}

.service-section.style-1 .section-wrapper .service-item:nth-child(2),
.service-section.style-1 .section-wrapper .service-item:nth-child(5),
.service-section.style-1 .section-wrapper .service-item:nth-child(8),
.service-section.style-1 .section-wrapper .service-item:nth-child(11) {
  float: left;
  width: 50%;
}

.service-section.style-1
  .section-wrapper
  .service-item
  .service-inner
  .service-content
  h4 {
  margin-top: 0;
  margin-bottom: 15px;
}

.service-section.style-1
  .section-wrapper
  .service-item
  .service-inner
  .service-content {
  padding: 45px 30px;
  background-color: #fff;
}
.service-section.style-1
  .section-wrapper
  .service-item
  .service-inner
  .service-content
  p {
  margin-bottom: 30px;
  color: #696969;
  font-size: 1rem;
}

.service-section.style-1
  .section-wrapper
  .service-item
  .service-inner
  .service-content
  h4
  a {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;

  /* max-width: 440px; */
}
.service-section.style-1
  .section-wrapper
  .service-item
  .service-inner
  .service-content
  .lab-btn {
  padding: 10px 20px;
  line-height: 45px;
  border: 2px solid #eb1d25;
  border-radius: 2px;
  background: #eb1d25;
  color: #fff;
  transition: all 0.3s ease;
}

.service-section.style-3 .section-wrapper .service-item .service-content {
  width: calc(100% / 2);
  background-color: #fff;
  padding: 30px;
}

.service-section.style-1
  .section-wrapper
  .service-item
  .service-inner
  .service-thumb
  img {
  width: 100%;
  height: 100%;
}

section.doctor-section.padding-tb {
  background-color: #d9e9ef;
}

.doctor_speality_wrapper {
  display: flex;
  align-items: center;
}

section.doctor-section.padding-tb {
  background: #ee7d7d;
  color: #fff;
}

.spe_desp {
  width: 40%;
  color: #58595b;
}

.spe_doc {
  width: 40%;
}

.spe_intro {
  width: 40%;
}

.spec_image {
  line-height: 0;
  position: relative;
}

.spec_image:before {
  left: 0;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  width: 70%;
  margin: auto;
  padding-bottom: 70%;
  background-color: #eb1d25;
  border-radius: 50%;
  content: "";
  position: absolute;
}

.spec_image:after {
  left: 0;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  width: calc(70% + 30px);
  margin: auto;
  padding-bottom: calc(70% + 30px);
  border-radius: 50%;
  content: "";
  position: absolute;
  border: 1px dashed #505050;
}

.spec_image img {
  width: 100%;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.spec_doctor_det p {
  color: #eb1d25;
}

.spec_team_wrapper {
  display: flex;
  width: 100%;
}

.element_one {
  padding: 10px;
}

.element_one .section-header h2 {
  color: #fff;
  z-index: 1 !important;
}

.department_section .swiper {
  margin-top: -100px;
}

.department_section .swiper-slide {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 10px;
  overflow: hidden;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.department_section .swiper-slide img {
  border-radius: 50%;
  overflow: hidden;
  width: 200px;
  height: 200px;
}

.department_section .swiper-slide img:hover {
  transform: scale(1);
  filter: brightness(60%);
  overflow: hidden;
}
.dert-content {
  padding: 10px;
  text-align: center;
}
.deparment_title h3 {
  font-size: 2.5em;
}

h3.deparment_title {
  margin-bottom: 10px;
}

.dert-content .lab-btn {
  padding: 10px 20px;
  line-height: 45px;
  border: 2px solid #eb1d25;
  border-radius: 2px;
  background: #eb1d25;
  color: #fff;
  transition: all 0.3s ease;
}

/* faqs */
.faq_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.faq_wrapper .accordion {
  width: 800px;
}

.faq_wrapper .item {
  background: #f0ebe1;
  margin-bottom: 5px;
  padding: 20px 20px;
}

.accordion .title {
  color: #85562b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.accordion strong {
  font-size: larger;
  font-weight: 800;
}

.accordion .content {
  color: #8b7f75;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  margin-top: 10px;
  /* border-top: 1px solid #8b7f75; */
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1, 0, 1, 0);
}

@media (max-width: 576px) {
  .service-section.style-1 .section-wrapper .service-item .service-inner {
    display: block;
  }

  .service-section.style-1
    .section-wrapper
    .service-item
    .service-inner
    .service-thumb {
    width: calc(100% / 1);
  }

  .service-section.style-1 .section-wrapper .service-item:nth-child(2),
  .service-section.style-1 .section-wrapper .service-item:nth-child(3),
  .service-section.style-1 .section-wrapper .service-item:nth-child(5),
  .service-section.style-1 .section-wrapper .service-item:nth-child(6),
  .service-section.style-1 .section-wrapper .service-item:nth-child(8),
  .service-section.style-1 .section-wrapper .service-item:nth-child(9),
  .service-section.style-1 .section-wrapper .service-item:nth-child(11),
  .service-section.style-1 .section-wrapper .service-item:nth-child(12) {
    width: 100%;
    float: none;
  }

  .service-section.style-1
    .section-wrapper
    .service-item
    .service-inner
    .service-content {
    width: 100%;
  }

  .doctor_team-grp {
    display: block;
  }

  .doctor_team-grp h2 {
    font-size: 1em;
  }

  .doctor-item.style-2.wow.fadeInUp {
    width: calc(100% / 1) !important;
  }
}

@media (max-width: 768px) {
  .doctor_team_section {
    width: calc(100% / 1);
  }

  .faq_wrapper .accordion {
    width: 100%;
  }
}
