.top__navbar {
  padding: 21px 0;
}

.top__navbar__section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-contact-info {
  display: flex;
  align-items: center;
  list-style: none;
}

.header-contact-info .item .item-inner {
  margin: 0 15px;
  display: flex;
  align-items: center;
}

.header-contact-info .item .item-inner .item-thumb {
  margin-right: 15px;
}

.item .item-content p {
  font-weight: bold;
  padding: 2px;
}

.header-bottom {
  z-index: 999;
  transition: all 0.3s ease;
}

.main-menu {
  grid-column: 3/4;
}

.bottom__header {
  background: #eb1d25;
}
.menu-link {
  height: 4rem;
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  list-style: none;
  /* background: #eb1d25; */
}
.menu-link li {
  color: #fff !important;
  padding: 15px 10px;
  margin: 0 10px;
  outline: none;
  text-decoration: none;
  list-style: none;
  font-weight: bold;
}
.menu-link li a {
  color: #fff !important;
  text-decoration: none;
}

.humberger__menu {
  display: none;
}

.form-group{
  margin-bottom: 10px;
  padding: 0px 10px;
}
.mb-3{
  margin-bottom: 30px;
  
}
.form-group .form-control{
  width: 100%;
  padding: 15px 10px;
  outline: none;
  border: #d1d1d1;
  border: 1px solid;
  border-radius: 5px;
}

.submenu__list {
  width: 200px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  top: 200px;
  visibility: hidden;
}
.megamenu__list {
  width: 1000px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  top: 240px;
  /* visibility: hidden; */
}

.submenu__list li {
  text-align: left;
}

.menu-link li .submenu__list li {
  float: none;
  border-bottom: 1px solid #ecf0f3;
}
.menu-link li .submenu__list li:last-child {
  border-bottom: none;
}
.menu-link li .submenu__list li a {
  padding: 10px 0;
  color: #000000 !important;
  /* padding-left: 20px; */
  text-align: left;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.menu-link li .submenu__list li a:hover {
  color: #eb1d25 !important;
}
.menu-link li .submenu__list li a::after {
  display: none;
}
.menu-link li .submenu__list li a::before {
  display: none;
}
.menu-link li .submenu__list li .submenu__list {
  left: 100%;
  top: 0;
}
.menu-link li:hover > .submenu__list {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}
.menu-link li:hover > a::after {
  transform: rotate(0deg);
}

.fa {
  margin-left: 5px;
}

.menu-link li:hover .fa {
  rotate: 180deg;
}

.menu-link .submenu__list li.active > a {
  color: #eb1d25;
}

.main_nav {
  background: #eb1d25;
  width: 100%;
}

.mega-box {
  position: absolute;
  top: 200px;
  width: 1000px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 3px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.mega-box .content {
  background: #fff;
  padding: 25px 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.content .row {
  line-height: 45px;
  display: flex;
  align-items: center;
}

.content .row header {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.content .row .mega-links {
  border-left: 1px solid rgba(255, 255, 255, 0.09);
  margin-left: -40px;
}

.mega-links li {
  /* list-style: none; */
  float: none;
  padding: 10px 10px;
}

.mega-box .mega-box-submenu li a:hover {
  color: #eb1d25 !important;
}

.content .row:nth-child(1),
.content .row:nth-child(2) {
  border-left: 0px;
}

.mega-box .mega-box-submenu li {
  padding: 10px 10px;
}

.mega-box .mega-box-submenu li a {
  color: #000 !important;
  padding: 5px;
  margin: 0px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  text-align: left;
  padding: 10px 10px;
}
.mega-box .mega-box-submenu li a:hover {
  color: #eb1d25;
  transform: rotate(0deg);
  transition: all 0.5s ease;
}

.mega-box-submenu li {
  float: none;
  border-bottom: 1px solid #ecf0f3;
}

.main-menu li:hover > .mega-box {
  z-index: 1;
  opacity: 1;
  visibility: visible;
}

.department_card_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-section.style-3 .section-wrapper .department_card_items:nth-child(3) {
  flex-direction: row-reverse;
}
.service-section.style-3 .section-wrapper .department_card_items:nth-child(4) {
  flex-direction: row-reverse;
}
.dept_card {
  display: flex;
  width: 500px;
}

.dept_detail {
  width: 50%;
  background: #fff;
  padding: 30px;
}

.dept_image {
  width: 50%;
}

.dept_detail .text-btn {
  font-size: 0.875rem;
  color: #000000;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  font-weight: bold;
}

/* treatment detail */

/* .doctor_team-grp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
}

.doctor-item {
  background-color: #fff;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.doctor-item {
  width: calc(100% / 3);
  /* box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); */

/* deparment slider */
.element_one {
  background-image: url(../images/banner/two-pharmacists.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 300px;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 100vmax rgb(23 24 24 / 70%);
}
/* footer section */

.foot-fixed {
  position: fixed;
  bottom: 0;
  background-color: white;
  width: 100%;
  padding: 20px 5px;
  text-align: center;
  z-index: 9;
  box-shadow: 1px -1px 3px #a7a7a7;
  display: none;
}

.hidden{
  display: none;
}

.footer-callback {
  z-index: 99;
  /* border: 1px solid red; */
  position: fixed;
  background-color: #000000d1;
  width: 100%;
  height: 100vh;
  bottom: 0;
  top:0;
}



.callback-body-content{
  width: 500px;
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0px 15px;
}

.callback-body{
  position: relative;
  background-color: white;
  padding: 15px 15px;
  border-radius: 10px;
}

.callback-body .close{
  width: 30px;
  height: 30px;
  background-color: red;
  line-height: 30px;
  margin: 0;
  text-align: center;
  position: absolute;
  top: -10px;
  right: -10px;
  color: #fff;
  border-radius: 50%;
  display: block;
}



.callback-body-content h1{
  font-size: 20px;
}

.foot-fixed .justify-btw{
  justify-content: space-between;
}
.foot-fixed .justify-btw svg{
  font-size: 25px;
}

.cm-wd-25{
  width: 20%;
}



section.footer-section.style-1 {
  background: #f1e8e8;
}
.footer-top {
  width: 100%;
  padding-top: 120px;
  padding-bottom: 110px;
}
.footer-top .row {
  display: flex;
  justify-content: space-between;
}

.footer-top h3 {
  padding: 10px;
  font-size: 24px;
}
.footer-top p {
  padding: 10px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #696969;
}

.footer-section.style-1 {
  background: url(../images/footer/bg1.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 70px;
}

.footer-section.style-1 .footer-top .contact-info ul li {
  color: #696969;
}

.footer-section.style-1 .footer-top .doctor-info ul li,
.footer-section.style-1 .footer-top .contact-info ul li,
.footer-section.style-1 .footer-top .service-info ul li {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  padding: 7px 0;
  /* font-size: 1.2rem; */
}
.footer-section.style-1 .footer-top .doctor-info ul li a,
.footer-section.style-1 .footer-top .contact-info ul li a,
.footer-section.style-1 .footer-top .service-info ul li a {
  color: #000;
  font-size: 16px;
  transition: all 0.3s ease;
}
.footer-section.style-1 .footer-top .doctor-info ul li i,
.footer-section.style-1 .footer-top .service-info ul li i {
  font-size: 1.2rem;
  margin-right: 5px;
}
.footer-section.style-1 .footer-top .time-info .dep-item {
  padding: 8px 0;
}
.footer-section.style-1 .footer-top .footer-about {
  margin-bottom: 40px;
}

.footer_card {
  width: 375px;
}

ul.lab-ul {
  list-style: none;
}

.footer-section.style-1 .footer-top .service-info ul li a:hover,
.footer-section.style-1 .footer-top .doctor-info ul li a:hover,
.footer-section.style-1 .footer-top .contact-info ul li a:hover {
  color: #eb1d25;
  transition: all 0.3s ease-in-out;
}
.footer-section.style-1 .footer-bottom span a:first-child {
  color: #eb1d25;
}

.footer-section.style-1 .footer-top .contact-info ul li i {
  color: #eb1d25;
}

.footer-section.style-1 .footer-top .service-info ul li:hover .fa,
.footer-section.style-1 .footer-top .doctor-info ul li:hover .fa {
  color: #eb1d25;
  transition: all 0.3s ease;
}

.service-section.style-3 .section-wrapper .col-12:nth-child(3) .service-item {
  flex-direction: row-reverse;
}

/* TreatmentDetail */

.treatment_wrappper {
  display: flex;
  /* align-items: center; */
  width: 100%;
  flex-direction: row-reverse;
}

.left_treat_sec {
  width: 40%;
  margin-right: 20px;
}

.right_treat_sec {
  width: 80%;
}

.main-content .post-content {
  padding: 15px 0;
}

.post-content h3 {
  font-size: 1.5em;
  line-height: 1.5;
}

.post-content p {
  font-size: 1rem;
  color: #696969;
}

.main-content .post-content ul li {
  padding: 5px 0;
  list-style: none;
}

/* .main-content .post-content ul li:nth-child(odd) {
  padding-right: 20px;
} */

.main-content .post-content ul li,
.single-sidebar .timetable-widget .widget-wrapper ul li,
.single-sidebar .popular-post-widget .widget-wrapper ul li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.main-content .post-content .doctor-list {
  margin: 0 -15px;
}

.doctor-list {
  display: flex;
  flex-wrap: wrap;
}

.main-content .post-content .doctor-list .post-item .post-item-inner {
  box-shadow: 0px 0px 10px -1px rgba(0, 0, 0, 0.1);
  margin: 15px;
  padding: 20px;
  display: flex;

  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: center;
}

.main-content
  .post-content
  .doctor-list
  .post-item
  .post-item-inner
  .post-content
  .member-contact {
  display: block;
}
.main-content
  .post-content
  .doctor-list
  .post-item
  .post-item-inner
  .post-content
  .member-contact
  li {
  display: flex;
}

.doctor-list .post-item-inner h5 {
  font-size: 1.125em;
  line-height: 1.5;
  color: #000;
  padding: 5px 0;
}

.main-content
  .post-content
  .doctor-list
  .post-item
  .post-item-inner
  .post-content {
  padding-left: 15px;
  width: calc(100% - 80px);
}

.treatment_lists_section {
  margin-bottom: 30px;
  padding: 30px;
  background-color: #f5f9ff;
}

.treatment_lists_section .section-header h2 {
  color: #000;
}

.resp_mobile_menu {
  display: none;
}

/* .mobile_menu_section {
  background: #eb1d25;
  width: 100%;
  height: 100vh;
  top: 0;
  position: absolute;
  right: 0;
} */

/* Treatment List Section */

.treatment_list_wrapper h3 {
  font-size: 1.5em;
}

.treatment_list_wrapper ul {
  list-style: none;
}

.treatment_list_wrapper ul li {
  padding: 5px 0;
  background-color: #fff;
  color: black;
  margin: 20px 10px;
}
.treatment_list_wrapper ul li a {
  display: block;
  color: #000000;
  padding: 13px 20px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.treatment_list_wrapper ul li:hover {
  background-color: #eb1d25;
  color: #fff;
}

.treatment_list_wrapper ul li:hover a {
  color: #fff;
}

.doctor_team_section {
  width: calc(100% / 3);
}

.doctor_card {
  color: black;
  background-color: #fff;
  box-shadow: 0 3px 17px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
}

.doc_header {
  display: flex;
  padding: 20px;
}

.doctor_card .doc_header .doc-avatar {
  width: 100px;
  box-shadow: 0 4px 40px rgba(0, 0, 0, 0.1);
  height: 100px;
  border: 10px solid #fff;
  flex: 0 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

.doc-avatar img {
  border-radius: 50%;
  width: 80px;
}

.doctor_card .doc_desc {
  width: calc(100% - 100px);
  padding-left: 20px;

  position: relative;
  font-size: 14px;
  margin-bottom: 5px;
  padding-right: 30px;
}

.doc-designation {
  color: #737476;
  font-weight: 400;
  border-bottom: 1px solid #e1e1e1;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

.doc-specialization {
  color: #444545;
  font-weight: 500;
  margin-bottom: 8px;
}
.doc-location {
  color: #444545;
  display: flex;
  align-items: center;
  gap: 7px;
}

.doc-expertise {
  border-top: 1px solid #e1e1e1;
  padding: 20px;
}

.doc-expertise .speciality-expertise-title {
  font-size: 11px;
}

.doc-expertise ul {
  margin: 0 -10px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.doc-expertise ul li {
  width: 50%;
  margin-bottom: 10px;
  padding: 0 10px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
}

.doc-expertise .doc-tabs-anchor a {
  border-bottom: 3px solid transparent;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 5px;
}

.doc-expertise .doc-tabs-anchor a {
  border-bottom: 3px solid transparent;
  padding-bottom: 5px;
  display: inline-block;
  margin-bottom: 5px;
  margin-right: 50px;
  font-size: 12px;
}

.doc-expertise .doc-tabs-anchor a.active {
  color: #eb1d25;
}

svg {
  margin-right: 5px;
}

.doc-tabs-holder {
  max-height: 220px;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 5px;
}
