.doctor-header-section {
  background-image: url("../images/banner/pageheader.jpg");
  padding-top: 105px;
  padding-bottom: 80px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: none;
  background-color: #f6f6f6;
}

.detail_wrapper {
  padding: 40px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.doctor_content {
  display: flex;
}

.doctor_thumb {
  width: 50%;
}

.doctor_desc {
  width: 50%;
}

.section-heading {
  text-align: left;
  margin-bottom: 10px;
}

.section-heading h5 {
  line-height: 1.5;
  margin-bottom: 5px;
  margin-top: 10px;
}
.section-heading h4 {
  line-height: 1.5;
  margin-bottom: 5px;
  margin-top: 10px;
}

.section-heading span {
  line-height: 1.5;
  margin-bottom: 5px;
  margin-top: 5px;
}

.about-content ul {
  list-style: none;
}

.about-content ul li {
  display: flex;
  flex-wrap: wrap;
  padding: 5px 0;
}

.about-content ul li .name {
  width: 25%;
}

.about-content ul li .info {
  width: 75%;
}

.about-content ul li .info p {
  padding-left: 20px;
}
.about-content ul li .info p::before {
  content: ":";
  top: 0;
  left: 0;
  color: #000000;
  margin-right: 20px;
}

.personal_achievement {
  display: flex;
}

.sp-personal-content {
  margin-top: 20px;
}

.progress_section {
  display: flex;
  width: calc(100% / 2);
}

.skill-bar {
  display: flex;
  justify-content: center;
}
.skill-item {
  margin-right: 45px;
}

.award-item {
  display: flex;
  flex-wrap: wrap;
}

.award-items h5 {
  font-size: 1.125em;
  line-height: 1.5;
  margin-left: 30px;
  margin-bottom: 1.845rem;
}

.skill-items h5 {
  font-size: 1.125em;
  line-height: 1.5;
  margin-left: 30px;
  margin-bottom: 1.845rem;
}

.award-item .award-inner {
  margin-left: 45px;
  margin-bottom: 0px;
}

.section-heading p {
  color: #696969;
}

@media (max-width: 768px) {
  .doctor_content {
    display: block;
  }

  .personal_achievement {
    display: block;
  }

  .awards_section {
    margin-top: 20px;
  }
  .doctor_thumb {
    width: 100%;
  }
  .doctor_desc {
    width: 100%;
  }
}
