@media (max-width: 575px) {
  .container {
    max-width: 100%;
  }
  h1 {
    font-size: 2.25em;
  }

  h2 {
    font-size: 1.5em;
  }

  .humberger__menu {
    display: block;
  }

  .main-menu ul {
    display: none;
  }

  .header-contact-info {
    display: none;
  }

  .banner-section {
    padding: 70px 0;
  }

  .banner-section.style-2 .contant-area .banner-contant h2 {
    font-size: 50px !important;
  }

  .banner-section.style-2 .contant-area .banner-contant h2 span {
    font-weight: 400;
  }
  .lab-btn {
    margin-top: 35px;
  }
  .banner-section.style-2 .contant-area .banner-contant span {
    z-index: 1;
  }

  .swiper-slide {
    width: 100% !important;
  }

  /* .treatment_wrappper {
    display: block;
  } */

  /* .left_treat_sec {
    width: 100%;
  }

  .right_treat_sec {
    width: 100%;
  } */

  .resp_mobile_menu {
    display: block;
  }

  .list_title_a,
  .list_title_b,
  .list_title_c,
  .list_title_d,
  .list_title_e,
  .list_title_f {
    display: none;
  }
  .resp_lists {
    display: block;
  }
}

@media (max-width: 992px) {
  .humberger__menu {
    display: block;
  }

  .header-contact-info {
    display: none;
  }

  ul.mobile-menu-link {
    display: block;
  }

  .mobile-menu-link {
    grid-column: 2/4;
    position: relative;
    z-index: 99;
  }

  .mobile-menu-link {
    height: 100vh;
    align-items: center;
    color: black;
    /* transition: all 2s linear; */
    transform: top;
    background: #2c2c2c;
  }

  .mobile-menu-link li {
    color: #000 !important;
    padding: 15px;
    outline: none;
    text-decoration: none;
    list-style: none;
    border-bottom: 1px solid #4b4b4b;
  }
  .mobile-menu-link li a {
    color: #fff !important;
    text-decoration: none;
    font-size: 15px;
  }

  li.resp-submenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .mobile-menu-link .resp-submenu span {
    color: #fff;
    font-weight: bolder;
    font-size: 22px;
  }

  .mobile-menu-link .submenu_list {
    max-height: 0;
    overflow: hidden;
    background: #000000;
  }

  .mobile-menu-link .submenu_list.show {
    max-height: 9999px;
    height: auto;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  }

  .main_nav {
    display: none;
  }

  .footer-section.style-1 .footer-top {
    padding-top: 120px;
    padding-bottom: 110px;
  }

  .footer-top .row {
    display: block;
  }
}

@media (max-width: 576px) {
  /* .department-section .style-2 .section-content .department-top .dep-tab li {
    width: calc(100% / 4 - 9px);
  } */

  /* .department-section.style-2 .section-wrapper .lab-item {
    width: calc(100% / 1);
  } */

  .service-section.style-1 .section-wrapper .service-item .service-inner {
    display: block;
  }

  .service-section.style-1
    .section-wrapper
    .service-item
    .service-inner
    .service-thumb {
    width: calc(100% / 1);
  }

  .service-section.style-1 .section-wrapper .service-item:nth-child(2),
  .service-section.style-1 .section-wrapper .service-item:nth-child(3),
  .service-section.style-1 .section-wrapper .service-item:nth-child(5),
  .service-section.style-1 .section-wrapper .service-item:nth-child(6),
  .service-section.style-1 .section-wrapper .service-item:nth-child(8),
  .service-section.style-1 .section-wrapper .service-item:nth-child(9),
  .service-section.style-1 .section-wrapper .service-item:nth-child(11),
  .service-section.style-1 .section-wrapper .service-item:nth-child(12) {
    width: 100%;
    float: none;
  }

  .service-section.style-1
    .section-wrapper
    .service-item
    .service-inner
    .service-content {
    width: 100%;
  }

  .doctor_team-grp {
    display: block;
  }

  .doctor_team-grp h2 {
    font-size: 1em;
  }

  .doctor-item.style-2.wow.fadeInUp {
    width: calc(100% / 1) !important;
  }
}

@media (max-width: 768px) {
  .section-wrapper .lab-item {
    width: calc(50% - 0px);
  }

  .callback-body-content{
    width: 100%;
  }

  .banner-wrapper img{
    width: 100% !important;
  }

  .foot-fixed {
    display: block;
  }

  .top__navbar {
    padding: 10px 0;
    /* margin-bottom: 10px; */
    position: fixed;
    width: 100%;
    z-index: 99;
    top: 0;
    box-shadow: 0px 0px 5px 0px gray;
    background-color: #fff;
  }
  section.navbar {
    margin-bottom: 94px;
    /* position: fixed; */
    width: 100%;
  }

  .mobile-menu-link .close-menu {
    display: flex;
    padding: pa;
    display: flex;
    align-items: center;
    background-color: #252525;
    padding: 20px 10px;
    justify-content: flex-end;
  }

  .mobile-menu-link .close-menu svg {
    color: white;
    font-size: 25px;
  }

  .bottom__header {
    /* background: #eb1d25; */
    position: fixed;
    top: 0;
    z-index: 999;
    right: 0;
    width: 75%;
    transition: all 0.5s cubic-bezier(1, 0, 1, 0);
  }

  .service_card_section {
    display: block;
  }

  .treatment_wrappper {
    display: block;
  }

  .left_treat_sec {
    width: 100%;
  }

  .right_treat_sec {
    width: 100%;
  }
  .image_outer {
    display: none;
  }

  /* model */

  .care_model .section-wrapper {
    padding: 0;
  }
  .res_section_wrapper {
    display: block;
  }

  .resp_image_outer {
    text-align: center;
    position: relative;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;

    border-bottom: 1px solid #000;
  }

  .resp_image_inner {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    margin: 20px 0;
    border: 1px dashed black;
    padding: 10px;
  }
  .resp_image_inner img {
    border-radius: 50%;
    width: 180px;
    height: 180px;
  }

  .container .popup-media iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    width: 90%;
    height: 50%;
    object-fit: contain;
    border: 3px solid #fff;
  }
}
@media (min-width: 1200px) {
  /* .department-section .style-2.section-content .department-top .dep-tab li {
    width: calc(100% / 9 - 9px);
  } */
  .department-section.style-2 .section-wrapper .lab-item {
    width: calc(100% / 5);
  }
}

/* @media (min-width: 550px) {
  .service-section.style-1 .section-wrapper .service-item:nth-child(2) {
    float: left;
    width: 100%;
  }
} */
