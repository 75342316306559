.about_section {
  background-size: cover;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-image: url("../images/banner/about-us-banner.jpg");

  padding: 170px 0;
}
.mission_section {
  background-size: cover;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-image: url("../images/banner/mission-banner.jpg");

  padding: 170px 0;
}

.about_section .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-start;
}
.mission_section .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-start;
}

.about_services {
  background: #f5f9ff;
}

.about_services .service_item {
  padding: 40px 24px;
}

.about_services .service-wrapper {
  display: flex;
}

.about_services .service_item {
  margin-bottom: 30px;
  padding: 40px 24px;
  background-color: #fff;
  width: calc(100% / 4);
  margin-right: 20px;
  transition: all 0.3s ease;
}
.about_services .service_item:hover {
  box-shadow: 0 15px 15px 3px rgba(0, 0, 0, 0.1);
}

.about_services .service-thumb {
  margin-bottom: 30px;
}

.about_services .service-content p {
  margin-bottom: 15px;
  color: #696969;
}

/* appointmet */
.appointment-section .section-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.appointment-section::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  background-image: url(../images/apport/01.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.appointment-section::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 50%;
  background-image: url(../images/apport/02.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}
.appointment-left {
  width: 50%;
  padding-right: 50px;
}
.appointment-right {
  width: 50%;
  padding-left: 50px;
}

.appointment-section {
  padding: 80px 0;
  position: relative;
}

.appointment-section .al-title {
  margin-bottom: 30px;
  color: #fff;
}
.appointment-section .ar-title {
  margin-bottom: 30px;
}

.appointment-right .ar-title h2 {
  color: #fff;
  margin: 0;
  line-height: 1.3;
}

.appointment-left .time-info .dep-item .dep-item-inner {
  padding: 15px 0;
  color: #fff;
  border-bottom: 1px solid #9b8df9;
  justify-content: space-between;
  display: flex;
}

.appointment-right form input {
  border: 1px solid rgba(236, 240, 243, 0.5);
  background: transparent;
  color: #fff;
  padding: 12px 15px;
  border-radius: 2px;
  margin-bottom: 30px;
  display: block;
  width: 100%;
  outline: none;
}

.appointment-right form select {
  padding: 12px 15px;
  border: 1px solid rgba(236, 240, 243, 0.5);
  background: transparent;
  color: #fff;
  border-radius: 2px;
  outline: none;
  margin-bottom: 30px;
  width: 100%;
}

.appointment-right form ::placeholder {
  color: #fff;
}

option {
  background: #eb1d25;
}

.counter-section {
  background: #eb1d25;
}

.counter-section .section-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.counter-section .section-wrapper .counter-item {
  width: calc(100% / 4);
}

.counter-item-inner .counter-thumb {
  margin-right: 20px;
}
.counter-content p {
  color: #fff;
}

.counter-item .counter-item-inner {
  margin: 10px;
}

.counter-item .counter-item-inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Achievment */
.achievement_section {
  background-size: cover;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-image: url("../images/banner/achievment.jpg");

  padding: 170px 0;
}

.certificate-section {
  background: #ee9e9e;
}

.certificatet_wrapper {
  display: flex;
}

.certificate_content {
  width: 50%;
}

.certificate_sites {
  width: 50%;
}

.certificats-site {
  margin-top: 40px;
}

.certificate-item-inner {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.certificate-thumb {
  width: calc(100% / 2);
}

.certificate_content p {
  line-height: 1.2;
}

/* mission vision */

.mission-mid-section {
  background: url(../images/banner/pattern-bg.png) repeat top left #fff;
}

.mission_wrapper {
  display: flex;
  align-items: center;
}

.mission_desc {
  width: 50%;
  color: #000;
  margin-bottom: 20px;
}

.mission-mid-section .section-header h2 {
  color: #000 !important;
}

.mission_thumb {
  width: 50%;
  margin-bottom: 20px;
}

.vision-title {
  margin-bottom: 20px;
  text-align: center;
}

.vision-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.vision_desc {
  width: 50%;
  margin-bottom: 20px;
  margin-left: 20px;
}

.vision_thumb {
  width: 50%;
  margin-bottom: 20px;
}

.vision_thumb img {
  width: 75px;
}

.vision_cards {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.vision_card {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

/* director desk */
.director-desk {
  background-size: cover;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-image: url("../images/banner/director.png");

  padding: 170px 0;
}

.director-desk .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-start;
}

.director_header {
  text-align: center;
  color: #000;
}

.director_section {
  padding: 30px 0;
  background-image: url("../images/banner/spec-left-bg.png");
  background-color: #034fa1;
  background-position: left;
  color: #fff;
}

.director_swiper_section {
  display: flex;
  align-items: center;
  width: 100%;
}

.direct_thumb {
  width: 40%;
  background: #fff;
  margin-right: 30px;
}

.direct_intro {
  width: 60%;
}

.direct_intro h3 {
  margin-bottom: 10px;
}

.direct_intro p {
  color: #fff;
}

.director_section .swiper-slide {
  padding: 20px 40px;
  background: #034fa1;
}
.swiper-slide h3 {
  margin-bottom: 10px;
}

.swiper-slide p {
  color: #000;
}

/* overview */

.overview_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
}

.overview_wrapper .overview_left {
  width: 50%;
  margin-right: 20px;
}

.overview_wrapper .overview_right {
  width: 50%;
}

.overview_right h3,
.overview_right p {
  margin-bottom: 20px;
}

/* milestone */
.milestone_section {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../images/banner/milestone.jpg");

  padding: 170px 0;
}

.milestone_section .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-start;
}

.project_section {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../images/banner/projects.jpg");

  padding: 170px 0;
}

.project_section .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .about_services .service-wrapper {
    display: block;
  }
  .about_services .service_item {
    width: calc(100% / 1);
  }

  .appointment-left {
    width: 100%;
    padding-right: 0px;
  }
  .appointment-right {
    width: 100%;
    padding-left: 00px;
  }

  .counter-section .section-wrapper .counter-item {
    width: calc(100% / 2);
  }

  .certificatet_wrapper {
    display: block;
  }

  .certificate-item-inner {
    display: block;
  }
  .certificate_content {
    width: 100%;
    margin-bottom: 30px;
  }

  .certificate_sites {
    width: 100%;
  }

  .certificate-thumb {
    width: 100%;
  }
  .certificate-thumb img {
    width: 100%;
  }

  .mission_wrapper {
    display: block;
  }

  .mission_desc {
    width: 100%;
  }

  .mission_thumb {
    width: 100%;
  }

  .vision-wrapper {
    display: block;
  }

  .vision_cards {
    display: block;
  }

  .vision_card {
    width: 100%;
  }

  .vision_desc {
    width: 100%;
  }

  .vision_thumb {
    width: 100%;
  }

  .vision_thumb {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .vision_thumb img {
    width: 70px;
  }

  .director_swiper_section {
    display: block;
  }

  .direct_thumb {
    width: 100%;

    margin-right: 0px;
  }

  .direct_intro {
    width: 100%;
  }

  .overview_wrapper {
    display: block;
    width: 100%;
  }

  .overview_wrapper .overview_left {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .overview_wrapper .overview_right {
    width: 100%;
  }

  .overview_right h3,
  .overview_right p {
    text-align: center;
  }
}
@media (max-width: 575px) {
  .counter-section .section-wrapper .counter-item {
    width: calc(100% / 1);
    margin-bottom: 40px;
  }

  .about_services .service_item {
    width: calc(100% / 1);
  }
  .about_services .service-wrapper {
    display: block;
  }

  .vision_desc {
    width: 100%;
    margin-bottom: 20px;
    margin-left: 0px;
  }
}
