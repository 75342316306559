.career_Sec .page-header-content {
  background: url(../images/career/careers-bg.jpg) no-repeat center 40px,
    linear-gradient(
      90deg,
      rgba(63, 69, 180, 1) 0%,
      rgba(0, 189, 255, 1) 60%,
      rgba(0, 189, 255, 1) 77%
    );
  padding-left: 20px;
  padding: 170px 0;
}

.career_Sec .breadcrumb {
  justify-content: flex-start;
}

.vacancy_sec {
  width: 950px;
  margin-top: -100px;
  margin-left: 15%;
  padding: 18px 50px;
  background: #fff;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-bottom: 20px;
}

.topRoundBg {
  background: #ffffff;
  padding: 15px 18px;
  border-radius: 12px 12px 0 0;
}

.vacancy_sec h2 {
  color: #fd9e5f;
  font-family: QuicksandBook;
  letter-spacing: -1px;
  font-size: 24px;
  padding-bottom: 5px;
}

.mobmedical {
  width: 48%;
  float: left;
  margin-bottom: 25px;
}

.medicalCol {
  margin-right: 15px;
}

.medicalHead {
  background: #e4e4e4;
  border-radius: 12px 12px 0 0;
  padding: 5px 0 2px 16px;
}

.tabContent {
  background: #f6f6f6;
  padding: 15px 15px 15px 15px;
  border-radius: 0 0 12px 12px;
}
.whiteBG1 {
  padding: 15px 10px 15px 15px;
  background: #fff;
}

.job_desc {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
}

.medical {
  width: 50%;
}

.non-medical {
  width: 50%;
}

.vac_card {
  border-radius: 8px;
  /* background: #daf7ff; */
  /* padding: 22px 30px; */
  min-height: 180px;
  width: 100%;
  text-align: center;
}

.cate_title {
  background: #e4e4e4;
  border-radius: 12px 12px 0 0;
  padding: 5px 0 2px 16px;
}

.vac_list {
  background: #fff;
  padding: 15px 20px 15px 20px;
  border-radius: 0 0 12px 12px;
}

.careersList {
  font-size: 12px !important;
  color: #666;
  padding: 5px 5px 0 5px;
  text-align: justify;
  border-bottom: 1px dashed #000;
}

.careersList h3 {
  color: #174f9a !important;
  font-size: 15px !important;
}

.careersList p {
  font-size: 12px !important;
  padding: 5px;
}

.vac_desc_sec {
  padding: 20px;
  background: #f6f6f6;
}

.apply_sec_ {
  display: flex;
  gap: 10px;
}

.apply_left {
  width: 50%;
}
.apply_right {
  width: 50%;
}

.apply_contact_sec {
  margin-top: 20px;
}

.doc-email-container {
  background: #ffeee2;
  padding: 30px 20px;
  border-radius: 15px;
  margin: 30px 0;
  border-bottom: 10px solid #fcdec8;
  display: flex;
  align-items: center;
}

.doc-email-inner-conatiner {
  width: 50%;
  padding: 0 20px;
}

.doc-email-inner-conatiner {
  width: 50%;
  padding: 0 20px;
}

.right-border::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  background: -moz-linear-gradient(
    top,
    rgba(252, 207, 177, 0) 0,
    rgba(252, 207, 177, 0.8) 15%,
    #fccfb1 19%,
    #fccfb1 20%,
    #fccfb1 50%,
    #fccfb1 81%,
    rgba(252, 207, 177, 0.8) 85%,
    rgba(252, 207, 177, 0) 100%
  );
  background: -webkit-linear-gradient(
    top,
    rgba(252, 207, 177, 0) 0,
    rgba(252, 207, 177, 0.8) 15%,
    #fccfb1 19%,
    #fccfb1 20%,
    #fccfb1 50%,
    #fccfb1 81%,
    rgba(252, 207, 177, 0.8) 85%,
    rgba(252, 207, 177, 0) 100%
  );
  background: linear-gradient(
    to bottom,
    rgba(252, 207, 177, 0) 0,
    rgba(252, 207, 177, 0.8) 15%,
    #fccfb1 19%,
    #fccfb1 20%,
    #fccfb1 50%,
    #fccfb1 81%,
    rgba(252, 207, 177, 0.8) 85%,
    rgba(252, 207, 177, 0) 100%
  );
  width: 2px;
  height: 100%;
}

.doc-email-inner-conatiner > ul > li {
  position: relative;
  background: 0 0 !important;
  padding: 15px 10px 15px 70px !important;
  border-bottom: 1px solid #e2ba9d;
  display: flex;
  gap: 5px;
  align-items: center;
}

.nurse::before {
  content: "";
  position: absolute;
  left: 20px;
  top: 5px;
  background: url(../images/icon/nurse.png) no-repeat;
  background-size: 80%;
  width: 36px;
  height: 41px;
}

.unmedical::before {
  content: "";
  position: absolute;
  left: 20px;
  top: 10px;
  background: url(../images/icon/medical-bag.png) no-repeat;
  background-size: 80%;
  width: 36px;
  height: 41px;
}
.doctor::before {
  content: "";
  position: absolute;
  left: 20px;
  top: 10px;
  background: url(../images/icon/doctor.png) no-repeat;
  background-size: 80%;
  width: 36px;
  height: 41px;
}

.doc-email-inner-conatiner > ul > li,
.doc-email-inner-conatiner > ul > li > a {
  font-size: 16px;
  color: #000 !important;
}

.modal_wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(189, 189, 189, 0.9);
  z-index: 99;
}

.modal_container {
  position: fixed;
  max-width: 40rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2rem 3rem;
  border-radius: 0.5rem;
  overflow-y: auto; /* Enable vertical scrolling */
}

/* .my_modal {
  display: flex;
  align-items: center;
}

.modal_field {
  width: 50%;
}

.modal_desc {
  width: 50%;
  margin-left: 10px;
} */

button.close_btn {
  right: 10px;
  position: absolute;
  top: 10px;
  border: none;
  background: #fff;
}

.modal_title {
  margin-bottom: 5px;
}

.modal-form label {
  margin: 10px 5px;
}

.modal-form form input {
  border: 1px solid #ecf0f3;
  padding: 10px 15px;
  display: block;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 10px;
}

.modal-form form select {
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ecf0f3;
}

/* .modal_card {
  width: 100%;
} */

@media (max-width: 565px) {
  .job_desc {
    display: block;
  }

  .medical {
    width: 100%;
  }

  .non-medical {
    width: 100%;
  }

  section.vacancy_sec {
    width: 100%;
    margin-left: 0px;
  }

  .apply_sec_ {
    display: block;
  }

  .apply_left {
    width: 100%;
  }

  .apply_right {
    width: 100%;
  }

  .doc-email-container {
    display: block;
  }

  .doc-email-inner-conatiner {
    width: 100%;
  }

  .doc-email-inner-conatiner > ul > li {
    display: block;
  }

  .modal_container {
    max-height: 80vh;
    overflow-y: auto;
  }

  .modal_wrapper {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(189, 189, 189, 0.9);
    z-index: 99;
  }

  .modal_container {
    position: fixed;
    max-width: 50rem;
    max-height: 80vh; /* Set maximum height */
    overflow-y: auto; /* Enable vertical scrolling */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 2rem 3rem;
    border-radius: 0.5rem;
    width: 90%;
  }

  button.close_btn {
    right: 10px;
    position: absolute;
    top: 10px;
    border: none;
    background: #fff;
    cursor: pointer;
  }

  .modal_title {
    margin-bottom: 5px;
  }

  .modal-form label {
    margin: 10px 5px;
  }

  .modal-form input,
  .modal-form select {
    border: 1px solid #ecf0f3;
    padding: 10px 15px;
    display: block;
    width: 100%;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .modal_card {
    margin-bottom: 20px; /* Add some bottom margin for spacing between fields */
  }
}
