.contact_section {
  background-size: cover;
  /* height: 500px; */
  background-repeat: no-repeat;
  background-image: url("../images/banner/contact.jpg");
  color: #fff;
  padding: 170px 0;
}

.contact_section .breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  border-radius: 0.25rem;
  justify-content: flex-start;
}

.contact_section .breadcrumb li {
  color: #000;
}

.contact_section .breadcrumb li.active {
  color: #eb1d25;
}

.contact_card .section_wrapper {
  padding: 60px 35px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.contact_wrapper {
  display: flex;
}

.contact_card_left {
  width: 50%;
}

.contact_card_right {
  width: 50%;
}

.contact-info {
  max-width: 350px;
}

.contact-info h3,
.here-form h3 {
  margin-bottom: 15px;
  font-size: 1.5em;
  line-height: 1.5;
}
.contact-info h6 {
  font-size: 1em;
}

.contact-info ul li {
  list-style: none;

  margin-bottom: 20px;
}

.contact-info p {
  color: #696969;
}

.contact-lists li::before {
  width: 4px;
  height: 100%;
  background-color: #eb1d25;
  top: 0;
  left: -15px;
  border-radius: 20px 0 0 20px;
}

.here-form form input {
  border: 1px solid #ecf0f3;
  padding: 10px 15px;
  display: block;
  width: 100%;
  margin-bottom: 30px;
}

.here-form form select {
  padding: 10px 15px;
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ecf0f3;
}

@media (max-width: 768px) {
  .contact_wrapper {
    display: block;
  }

  .contact_card_left,
  .contact_card_right {
    width: 100%;
  }
}
