@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body {
  font-family: "Roboto", sans-serif;
}

.container {
  max-width: 1200px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.padding-tb {
  padding: 60px 0;
}

p {
  color: #696969;
}

a {
  text-decoration: none;
}

h2 {
  font-size: 2.25em;
  line-height: 1.5;
}

h1 {
  font-size: 3.75rem;
}

h3 {
  font-size: 2em;
}

h4 {
  font-size: 1.25em;
  line-height: 1.5;
}

p {
  font-size: 1rem;
}

.menu-link .submenu.minus {
  display: none;
}

a.submenu .minus {
  display: none;
}

.text-center {
  text-align: center;
}

.page-title h2 {
  color: #eb1d25;
}

/* Margin */
.mb-20 {
  margin-bottom: 20px;
}

.cm-flex {
  display: flex;
}

a.submenu:hover > .plus {
  display: none;
}

a.submenu:hover > .minus {
  display: inline-block;
}
/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}
.footer-section.style-1 .footer-top .doctor-info ul li:hover,
.footer-section.style-1 .footer-top .service-info ul li:hover {
  margin-left: 10px;
}
/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
}

.lab-btn:before {
  content: "";
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
  transform-origin: 100% 0%;
  -webkit-transform-origin: 100% 0%;
}

.lab-btn:after {
  border-top: 3px solid #fff;
  border-right: 3px solid #fff;
  transform-origin: 0% 100%;
  -webkit-transform-origin: 0% 100%;
}

.lab-btn::before,
.lab-btn::after {
  content: "";
  z-index: 3;
  top: 0;
  left: 0;
  transform: scale(0);
  transition: 0.5s;
}

.lab-btn::before,
.lab-btn::after {
  content: "";
  width: 98%;
  position: absolute;
  height: 90%;
}

.lab-btn {
  line-height: 45px;
  width: 188px;
  /* -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease; */
  /* transition: all 0.3s ease; */
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 0 1.3rem;
  border: 2px solid #eb1d25;
  color: #fff;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  background-color: #eb1d25;
  display: inline;
}

.lab-btn:hover {
  background-color: #eb1d25;
  color: #fff;
}
.lab-btn:hover:before,
.lab-btn:hover:after {
  box-shadow: 0 0 24px rgba(0, 113, 239, 0.3);
  transform: scale(1);
}

.image_inner .center-image.isloading {
  animation: scaling-image-animation 1s ease;
}

/* .swiper-button-next,
.swiper-rtl .swiper-button-prev,
.swiper-button-prev:after {
  right: var(--swiper-navigation-sides-offset, 1px);
  left: auto;
  color: #000;
  border: 1px solid gray;
  background: #ebebe4;
  padding: 20px;
  border-radius: 50%;
  font-size: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0);
} */

.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 16px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  background: #ffffff;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 40px !important;
  /* border: 1px solid gray; */
  color: #000;
  box-shadow: 0px 0px 8px 0px #b5b5b5;
}

:root {
  /* --swiper-navigation-size: 24px !important; */
  --swiper-theme-color: #000;
}

ul.mega-box-submenu {
  display: flex;
  flex-wrap: wrap;
}

@keyframes scaling-image-animation {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }

  /* 50% {
    transform: scale(0.6);
  } */
  100% {
    transform: scale(1);
  }
}

.image_inner .center-image.isloading {
  transform: scale(0.3);
}

/* newsletter */
.newsletter-section.style-1 {
  background: #eb1d25;
  padding: 60px 0;
}

.newsletter-section.style-1 .section-wrapper .left .news-title {
  text-align: left;
}

.newsletter-section.style-1 .section-wrapper .left .news-title h2 {
  /* margin-bottom: 40px; */
  font-size: 2.25em;
  line-height: 1.5;
  color: #fff;
  text-align: center;
}
.newsletter-section.style-1 .section-wrapper .left .news-title p {
  text-align: center;
}

.newsletter-section.style-1 .section-wrapper .left .news-title a {
  font-size: 2.25em;
  color: #fff;
  text-align: center;
}
.newsletter-section.style-1 .section-wrapper .left {
  width: 100%;
  margin-bottom: 0;
}

/* clients Story */

.story_container_section {
  display: flex;
  align-items: center;
  margin: 0 -7px;
}

.resp_story_container {
  display: none;
}

.story_container_section .video-col-1 {
  width: 18%;
}

.story_container_section .video-col-2 {
  width: 21.33%;
}

.story_container_section .video-wrap {
  padding: 7px;
}

.story_container_section .video {
  position: relative;
}

.video-wrap .video .video-thumb.ratio1 {
  padding-bottom: 110%;
}

.video-wrap .video .video-thumb.ratio2 {
  padding-bottom: 80%;
}
.video-wrap .video .video-thumb {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  line-height: 0;
}

.video-wrap .video .video-thumb:not(.default) img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  transition: 0.3s;
}

.video-wrap .video .video-thumb .play-video {
  width: 48px;
  height: 48px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 55;
  transform: scale(0.9);
  transition: 0.3s;
  cursor: pointer;
}

.video-wrap .video .video-caption {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px;
  text-align: center;
  color: #fff;
  z-index: 5;
}

.video-wrap .video .video-caption p {
  font-size: 11px;
}
.video-wrap .video .video-caption p {
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}

.container .popup-media {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
}

.container .popup-media iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  width: 50%;
  height: 50%;
  object-fit: contain;
  border: 3px solid #fff;
}

.swiper-button-next,
.swiper-button-prev {
  width: 45px !important;
  height: 45px !important;
  line-height: 45px;
  text-align: center;
}

@media (max-width: 768px) {
  /*  clients story */
  .story_container_section {
    display: none;
  }

  .story_container_section .video-col-1,
  .story_container_section .video-col-2 {
    width: 100%;
  }

  .video-wrap .video .video-thumb.ratio1 {
    padding-bottom: 80%;
  }

  .resp_story_container {
    display: block;
  }
}
